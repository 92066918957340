import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <section id="services-section" className="services">
      <h1>Legal Services</h1>
      <ul>
        <li>Criminal Defense</li>
        <p>
          <p className="quote">
            Show me a person who has never made a mistake
            <br />
            and I’ll show you someone who has never achieved much.
            <br />– Joan Collins
          </p>
          <br /> <br />
          Mistakes are a part of life. They allow us to learn and grow as
          individuals. If we are never allowed to make mistakes, we will never
          realize where we are going wrong. A life without mistakes leads to a
          life without improvement.
          <br /> <br />
          We are <strong>human</strong>. We are <strong>imperfect</strong>. We
          make <strong>mistakes</strong>. Don't let yours prevent you from a
          trial.
          <br /> <br />
          As an attorney specializing in criminal defense, I am dedicated to
          upholding the principles of justice and fighting for your rights.
          <br /> <br />
          In the United States of America, we are innocent until proven
          otherwise. I will work to ensure that this fundamental right is
          protected. Whether you’re facing a complex legal challenge or simply
          need someone to advocate on your behalf, I am here to provide the
          expert guidance, unwavering support, and strong defense you need.
        </p>
        <li>Murder</li>
        <li>Theft</li>
        <li>Drug Possession</li>
        <li>Etc</li>
        <li>Divorce</li>
        <li>Land Dispute</li>
        <li>Custody</li>
      </ul>
    </section>
  );
};

export default Services;

/* TODO Add cards that are to expand revealing each appropriate legal service */
/* TODO OnHover of each card, the background image should change to reflect the currently
        hovered subject matter. */