import React from "react";
import "./About.css";
// import bgVideo from "../videos/texasCourthouseMirage.mp4";

const About = () => {
  return (
    <section id="about-section" className="about">
      <h2>SM TX LAW</h2>
      {/* <div>
      <video src={bgVideo} autoPlay loop muted/>
      </div> */}
      <div id="lawfirm-philosophy">
        <p>
          Sometimes the justice system can be challenging, and facing it alone
          can be daunting. That's why I founded <strong>SM TX LAW</strong> – to support people
          navigating tough legal battles. Not only am I somebody who will
          advocate for you, but I will also take the time to listen and
          understand your unique situation.
        </p>

        <p>
          At <strong>SM TX LAW</strong>, we're not just about providing legal expertise; we're
          about building genuine connections with our clients. We believe in
          open communication, honest advice, and fighting for your best
          interests. Regardless of the complexity of you legal situation, we
          will be there with you every step of the way, offering not just legal
          guidance, but also empathy and understanding.
        </p>

        <p>As Americans, every one of us has the right to a fair trial.</p>
      </div>
    </section>
  );
};

export default About;
