import React from "react";
import "./Contact.css";
import ContactInfo from "./ContactInfo";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <section id="contact-section" className="contact">
      <h1>Contact Us</h1>
      <ContactInfo />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ContactForm />
    </section>
  );
};

export default Contact;
