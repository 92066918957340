import React from "react";
import "./Intro.css";

const Intro = () => {
  return (
    <section id="intro" className="intro">
      <p className="intro-body">
        Client-focused and dedicated to justice,
        <br />
        SM TX LAW is THE law firm for all
        <br /> legal matters in the great state of Texas.
      </p>
    </section>
  );
};

export default Intro;
