import React from 'react';
import './NavBar.css';

const NavBar = () => {
  return (
    <nav className="navbar">
      {/* TODO Display texas outline to right of company name */}
      <h1 className="company-name">SM TX LAW</h1>
      <ul className="nav-links">
        <li><a href="#services-section">Services</a></li>
        <li>|</li>
        <li><a href="#about-section">Who We Are</a></li>
        <li>|</li>
        <li><a href="#contact-section">Contact Us</a></li>
      </ul>
    </nav>
  );
};

export default NavBar;
