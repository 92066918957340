import React from "react";

const ContactInfo = () => {
  return (
    <div className="contact-info">
      <span className="contact-header">Phone: </span>512-222-8353
      <br />
      <span className="contact-header">Email: </span>
      <a href="mailto:jon.robinson@smtxlaw.com">jon.robinson@smtxlaw.com</a>
      <br />
      <span className="contact-header">Address:</span>
      <br />
      697 S. Seguin Ave.
      <br />
      New Braunfels, TX 78130
      <br />
      <br />
      <br />
      <iframe
        title="SMTXLAW Office"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3465.776964605805!2d-98.11832462321931!3d29.697245375100124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865cbd2dd6de684d%3A0x5b13cfa4c04e577c!2sJon%20Robinson%20Attorney%20at%20Law!5e0!3m2!1sen!2sus!4v1731371024790!5m2!1sen!2sus"
        width="500"
        height="400"
        style={{ border: "2px solid", borderRadius: "12px" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default ContactInfo;
