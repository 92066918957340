import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-media-links">
        <a href="https://www.linkedin.com/">
          <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" />
        </a>
        <a href="https://www.instagram.com/">
          <img src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png" alt="Instagram" />
        </a>
        <a href="https://www.facebook.com/">
          <img src="https://cdn-icons-png.flaticon.com/512/124/124010.png" alt="Facebook" />
        </a>
        <div id='copyright'>©2024 SM TX LAW</div>
      </div>
      
    </footer>
  );
};

export default Footer;

/* TODO Update links to using links to actual social media profiles */